var $doc = $(document),
    $win = $(window),
    $html = $("html"),
    $body = $("body");

if ('serviceWorker' in navigator && !$html.hasClass("no-sw")) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/sw.js').catch(function(e) {
            console.error('Error during service worker registration:', e);
        });
    });
}

(function($) {
    $.event.special.touchstart = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
        }
    };
    $.event.special.touchmove = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
        }
    };
})(jQuery);
