(function ($) {
    let $fn = $(".part_form_booking");

    if ($fn.length && $win.width() > 959) {
        let $lang = $("html").attr("lang");
        if($lang !== "ru") {
            if($lang == "it") {
                $lang = 'en';
            }
            $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+$lang+".min.js").done(function () {
                initDatepickers();
            });
        }
        else {
            initDatepickers();
        }


        function initDatepickers() {
            let $dp_in_alt = $fn.find("[data-alt=\"checkin\"]"),
                $dp_out_alt = $fn.find("[data-alt=\"checkout\"]"),
                $today = new Date(),
                $prefill_dates = $fn.data("prefill");

            let $dp_options = {
                language: $lang,
                dateFormat: "d",
                altFieldDateFormat: "yyyy-mm-dd"
            };

            let $dp_in = $fn.find("[data-datepicker=\"checkin\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_in_alt,
                minDate: $today,
                onSelect:function ($fd,$d,$inst) {
                    let $month = $.fn.datepicker.language[$lang]['months'][$d.getMonth()];
                    $inst['$el'].closest(".wrp_datepicker").find("[data-month]").find("i").html($month);
                    $dp_out.update({
                        minDate: new Date($d.getTime() + (24*60*60*1000))
                    });
                    setTimeout(function () {
                        if($d > new Date($dp_out['$el'].siblings("input").val())){
                            $dp_out.selectDate(new Date($d.getTime() + (24*60*60*1000)))
                        }
                    },50);
                }
            })).data('datepicker');

            let $dp_out = $fn.find("[data-datepicker=\"checkout\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_out_alt,
                minDate: new Date($today.getTime() + (24*60*60*1000)),
                startDate: new Date($today.getTime() + (24*60*60*1000)),
                onSelect:function ($fd,$d,$inst) {
                    let $month = $.fn.datepicker.language[$lang]['months'][$d.getMonth()];
                    $inst['$el'].closest(".wrp_datepicker").find("[data-month]").find("i").html($month);
                }
            })).data('datepicker');

            /*Plneni datumu*/

            if($prefill_dates){
                $dp_in.selectDate($today);
                $dp_out.selectDate(new Date($today.getTime() + (24*60*60*1000)))
            }
            else {
                $fn.find("[data-datepicker=\"checkin\"]").closest(".wrp_datepicker").find("[data-month]").find("i").html($.fn.datepicker.language[$lang]['months'][$today.getMonth()]);
                $fn.find("[data-datepicker=\"checkout\"]").closest(".wrp_datepicker").find("[data-month]").find("i").html($.fn.datepicker.language[$lang]['months'][$today.getMonth()]);
            }
        }
    }
})(jQuery);
