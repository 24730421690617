(function ($) {
    let $fn = $(".comp_visual");

    if($fn.length) {
        let $slider = $fn.find("[data-visual-slider]"),
            $slider_caption = $fn.find("[data-visual-caption]"),
            $slider_autoplay = $slider.data("visual-slider");

        let $autoplay = true;

        if ($slider_autoplay === "0" || $slider_autoplay === "") {
            $autoplay = false;
        }

        let $slider_for = $slider,
            $slider_caption_for = $slider_caption;

        if ($slider_caption.children().length < 1 || $slider.children().length < 1) {
            $slider_caption_for = null;
            $slider_for = null;
        }

        if ($slider.children().length > 1) {
            $slider.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: $autoplay,
                autoplaySpeed: $slider_autoplay,
                arrows: false,
                pauseOnHover: false,
                asNavFor: $slider_caption_for,
                dots: true
            });
        }

        if ($slider_caption.children().length > 1) {
            $slider_caption.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                arrows: false,
                dots: false,
                asNavFor: $slider_for,
                adaptiveHeight: true
            });
        }

        $fn.on("click","[data-scroll]",function() {
            $fn.next("section").anchor_anim(500);
        });
    }
})(jQuery);